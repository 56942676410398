<template>
  <div class="section-enter-code">
    <input
      v-for="i in digits"
      :key="i"
      :ref="`code${i}`"
      type="text"
      pattern="\d*"
      maxlength="1"
      class="code-digit"
      v-model="code[i]"
      @input="input($event, i)"
      @keyup.delete="code[i] === '' && i !== 1 ? $refs[`code${i - 1}`].focus() : () => {}"
      @paste="triggerPaste"
      :autocomplete="i === 1 ? 'one-time-code' : false"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      focusIndex: 1,
      digits: 6,
      code: {
        0: '',
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
        6: '',
      },
    };
  },
  mounted() {
    this.setInitialFocus();
  },
  methods: {
    setInitialFocus() {
      this.$refs.code1.focus();
    },
    input(e, index) {
      console.log(e);

      if (e.target.value === '') {
        if (index !== 1) {
          this.$refs[`code${index - 1}`].focus();
        }
      } else if (index !== this.digits) {
        this.$refs[`code${index + 1}`].focus();
      }

      // Emit full code
      this.$emit('update:modelValue', this.fullCode);
    },
    triggerPaste(e) {
      // Stop data actually being pasted into div
      e.stopPropagation();
      e.preventDefault();

      // Get pasted data via clipboard API
      const clipboardData = e.clipboardData || window.clipboardData;
      const pastedData = clipboardData.getData('Text');

      // Do whatever with pasteddata
      if (pastedData.length === +pastedData?.length ?? 0) {
        pastedData.split('').forEach((number, index) => {
          this.code[index + 1] = number;
        });
        this.$refs[`code${this.digits}`].focus();
      }
    },
  },
  computed: {
    fullCode() {
      return Object.values(this.code).join('');
    },
  },
};
</script>

<style lang="sass" scoped>
.section-enter-code
  @apply pt-8 px-8 pb-5 flex

.code-digit
  @apply focus:outline-none w-full h-16 text-center bg-transparent border-b border-gray-light
  font-size: 32px
  appearance: none
  transition: border-color var(--transition)

  &:focus
    border-color: var(--primary-back-color, var(--klubba-primary-back-color))

  &:not(:last-child)
    @apply mr-3
</style>
