<template>
  <div class="page-enter-code page-content">
    <KlubbaDarkBgText>
      <template #main>
        <p>{{ club.name }} uses KLUBBA to manage subscriptions.
          It’s fast an easy to create a KLUBBA account
          and you can use it to manage your subscriptions
          and memberships across multiple different organisations.</p>
      </template>
      <template #accent>
        Enter the unlock code sent to your mobile number {{ phoneNumber }}
      </template>
      <template #links>
        <p @click="isTermsVisible = true">Terms and conditions</p>
        <p @click="isPrivacyVisible = true">Privacy policy</p>
      </template>
    </KlubbaDarkBgText>

    <klubba-enter-code v-model="verifiedCode"/>

    <transition name="fade-bottom" mode="out-in">
      <div v-if="!timerEnabled" class="send-again">
        <span @click="sendAgain">Send again</span>
      </div>
      <div v-else class="send-again-timer">
        Send again in {{ timerCount }}s
      </div>
    </transition>

    <div @click="$router.push($route.fullPath.replace('enter-code', 'request-code'))" class="try-another">
      Try another number
    </div>

    <div class="button-wrap">
      <klubba-button
        @click="enterCode"
        buttonText='Unlock'
        :customClass='customClass'
        :loading="loading"
        :disabled="verifiedCode.length !== 6"
      />
    </div>

    <!-- Modals -->
    <BaseModal :active="isTermsVisible" @close="isTermsVisible = false">
      <KlubbaRichText v-html="club.terms_and_conditions" />
    </BaseModal>

    <BaseModal :active="isPrivacyVisible" @close="isPrivacyVisible = false">
      <KlubbaRichText v-html="club.privacy_policy" />
    </BaseModal>
  </div>
</template>

<script>
import swal from 'sweetalert';

import firebase from 'firebase';
import KlubbaButton from '@/components/default/KlubbaButton.vue';
import KlubbaEnterCode from '@/components/custom/KlubbaEnterCode.vue';
import BaseModal from '@/components/BaseModal.vue';
import KlubbaRichText from '@/components/default/KlubbaRichText.vue';
import KlubbaDarkBgText from '@/components/custom/KlubbaDarkBgText.vue';

import firebaseApp from '@/config/firebase';

export default {
  components: {
    KlubbaDarkBgText,
    KlubbaRichText,
    KlubbaButton,
    KlubbaEnterCode,
    BaseModal,
  },
  data() {
    return {
      timerCount: 30,
      loading: false,
      timerEnabled: false,
      customClass: 'bg-primary',
      verifiedCode: '',
      isTermsVisible: false,
      isPrivacyVisible: false,
      provider: null,
    };
  },
  computed: {
    club() {
      return this.$store.getters.club;
    },
    phoneNumber() {
      return this.$store.getters.getUserKey('phone_number');
    },
  },
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount -= 1;
        }, 1000);
      }
    },
    timerCount: {
      handler(value) {
        if (value === 0) {
          this.pauseTimer();
          this.timerCount = 30;
        }

        if (value > 0 && this.timerEnabled) {
          setTimeout(() => {
            this.timerCount -= 1;
          }, 1000);
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  methods: {
    playTimer() {
      this.timerEnabled = true;
    },
    pauseTimer() {
      this.timerEnabled = false;
    },
    sendAgain() {
      this.playTimer();
      if (!this.provider) {
        this.provider = new firebase.auth.PhoneAuthProvider();
      }
      this.provider.verifyPhoneNumber(this.phoneNumber, window.recaptchaVerifier);
    },
    async enterCode() {
      this.loading = true;
      const app = this;

      window.confirmationResult.confirm(app.verifiedCode).then(async (result) => {
        // User signed in successfully.
        // Check if we already have a document for him.
        console.log('Confirmation result', result);
        const userQuery = await firebaseApp.firestore().collection('users').doc(result.user.uid).get();
        const clubSpecificExists = (await firebaseApp.firestore().collection('users').doc(result.user.uid).collection('club_specific')
          .doc(app.club.id)
          .get()).exists;

        if (!userQuery.exists) {
          app.$router.push(`/${app.club.id}/email`);
        } else if (userQuery.exists && !clubSpecificExists) {
          app.$router.push(`/${app.club.id}/notifications`);
        } else {
          app.$router.push(`/${app.club.id}/${app.$route?.query?.proceedTo ?? 'add-wallet'}`);
        }
      }).catch((error) => {
        // User couldn't sign in (bad verification code?)
        console.log('Firebase auth error: ', error);
        swal('Error', error.message, 'error');
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.send-again-timer
  @apply text-center text-gray-dark

.send-again
  @apply text-center font-bold
  span
    @apply underline cursor-pointer
    color: var(--primary-back-color, var(--klubba-primary-back-color))

.try-another
  @apply underline cursor-pointer mt-3 text-center
  color: var(--primary-back-color, var(--klubba-primary-back-color))

.button-wrap
  @apply p-8 mt-auto
</style>
