<template>
<div class="klubba-dark-bg-text">
  <div v-if="!!$slots.main" class="main">
    <slot name="main" />
  </div>

  <div v-if="!!$slots.accent" class="accent">
    <slot name="accent" />
  </div>

  <div v-if="!!$slots.links" class="links">
    <slot name="links" />
  </div>
</div>
</template>

<script>
export default {
  name: 'KlubbaDarkBgText',
};
</script>

<style lang="sass" scoped>
.klubba-dark-bg-text
  @apply p-8 bg-primary-dark text-white

.description, .accent
  @apply tracking-spacing-7

.accent
  @apply font-bold mt-9 mb-7

.links
  @apply text-xs leading-8 underline text-primary tracking-spacing-7 font-bold

  line-height: 1.5

  :deep(>*)
    @apply cursor-pointer

  :deep(>*:not(:first-child))
    @apply mt-3
</style>
